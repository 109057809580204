<div
  class="selected-shop"
  [ngClass]="{
    'selected-shop__expanded': settings?.expand,
    'selected-shop__enable-hover': userShops?.length && settings?.expand
  }"
  #menuTrigger
>
  <div class="selected-shop__photo">
    <img [src]="activeShop?.shopPhotoMediaPath" class="nav-img" />
  </div>
  <div class="selected-shop__info">
    <div class="selected-shop__info__name">{{ activeShop?.name }}</div>
    <ooblee-star-rating [rating]="activeShop?.averageGrade"></ooblee-star-rating>
  </div>
  <div class="selected-shop__toggle-icon" *ngIf="userShops?.length && settings?.expand">
    <kiss-svg-icon icon="caret-down" class="ooblee-btn__icon"></kiss-svg-icon>
  </div>
</div>

<kiss-menu
  [menuTrigger]="menuTrigger"
  contentClass="kiss-menu-shop-select"
  xPosition="right"
  *ngIf="userShops?.length"
>
  <ooblee-spinner size="64px" thickness="6px" color="#ff6067" *ngIf="isLoading"></ooblee-spinner>
  <div class="shop-select__content">
    <button
      kiss-menu-item
      *ngFor="let shop of userShops"
      class="shop-select__item"
      [ngClass]="{ 'active-shop': shop.id == activeShop?.id }"
      (click)="onActiveShopChange(shop)"
    >
      <div class="shop-select__item__photo">
        <img [src]="shop?.shopPhotoMediaPath" class="nav-img" />
      </div>
      <div class="shop-select__item__name">{{ shop?.name }}</div>
      <div class="shop-select__item__dot" *ngIf="shop.id == activeShop?.id"></div>
    </button>
  </div>
</kiss-menu>
