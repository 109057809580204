import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { KissNavigationSidebar } from '@kiss/components/kiss-layout';
import { AuthenticationService } from 'app/shared/auth/authentication.service';
import { ShopModel, ShopsForLoggedUsers } from 'app/shared/models/shops-for-logged-users.model';
import { ShopService } from 'app/shared/services/shop/shop.service';
import { UserService } from 'app/shared/services/user/user.service';
import { Subject, from, takeUntil } from 'rxjs';

@Component({
  selector: 'ooblee-shop-select',
  templateUrl: './shop-select.component.html',
  styleUrls: ['./shop-select.component.scss'],
})
export class ShopSelectComponent implements OnInit, OnDestroy {
  activeShop: ShopModel;
  @Input() settings: KissNavigationSidebar;
  userShops: ShopsForLoggedUsers = [];
  isLoading = true;

  private _destroy: Subject<void>;

  constructor(
    private _shopService: ShopService,
    private _router: Router,
    private _userService: UserService,
    private _authService: AuthenticationService,
    private _authFire: AngularFireAuth
  ) {
    this._destroy = new Subject();
  }

  ngOnInit(): void {
    this._shopService.onShopChange.pipe(takeUntil(this._destroy)).subscribe((data) => {
      this.activeShop = data;
    });

    this._userService
      .userDataChange()
      .pipe(takeUntil(this._destroy))
      .subscribe((data) => {
        if (!data) return;
        this._getShopData();
      });
  }

  private _getShopData(): void {
    this._shopService
      .getShopApi()
      .subscribe((response) => {
        this.userShops = response;
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  onActiveShopChange(data: ShopsForLoggedUsers[0]) {
    if (data.id === this.activeShop.id) return;
    this._shopService.saveActiveShop(data);
    this._router.navigate(['/']);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
