import { FirebaseOptions } from '@angular/fire/app';

export class EnvConfig {
  static CDN = 'https://cdn.ooblee.me';
  static SPRITE_URL = 'assets/svg/icons.svg?version=2024-04-0223:44:26';
  static API_VERSION = 'v1';
  static STRIPE_CONNECT = 'https://connect.stripe.com/express_login';
  static APP_VERSION = '1.0.7';
  static LANG_VERSION = '1.0.9';
  static FIREBASE: FirebaseOptions = {
    projectId: 'ooblee',
    apiKey: 'AIzaSyARrv_jV8-2KjfgAl-tCOdcd6SMlqP6iqU',
  };
  static SHOP_GENERATOR_PROD = 'https://shop.ooblee.me/';
}

// shop admins
// https://shop-admin-test.ooblee.me
// https://shop-admin.cro-prod.ooblee.me
// https://shop-admin.mne-prod.ooblee.me
// https://shop-admin.de-prod.ooblee.me
